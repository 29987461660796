<script setup>
import DialogModal from '@/Components/DialogModal.vue';
import { mdiArrowRight, mdiCheckCircle, mdiTouchTextOutline } from '@mdi/js';

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    currentBinIndex: {
        type: Number,
        required: true,
    },
    lines: {
        type: Array,
        required: true,
    },
    scansById: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['close', 'select']);

const select = (index) => {
    emit('select', index);
    emit('close');
};

const isCompleted = (line) => {
    return line.quantity === props.scansById.get(line.id)?.sum('quantity') ?? 0;
};
</script>

<template>
    <DialogModal :show @close="emit('close')">
        <template #content>
            <v-list color="primary">
                <template v-for="(line, index) in lines" :key="index">
                    <v-list-item
                        :active="index === currentBinIndex"
                        :prepend-icon="
                            index === currentBinIndex
                                ? mdiArrowRight
                                : mdiTouchTextOutline
                        "
                        :append-icon="isCompleted(line) ? mdiCheckCircle : null"
                        :class="{
                            'tw-bg-gray-300': index === currentBinIndex,
                        }"
                        @click="select(index)"
                    >
                        <div>
                            <span class="tw-text-2xl tw-font-bold">{{
                                line.bin_code
                            }}</span>
                        </div>
                        <div class="tw-flex tw-items-center">
                            <div>
                                <div>
                                    <span class="tw-text-gray-500">{{
                                        line.item.Description
                                    }}</span>
                                    <span
                                        v-if="line.item['Description 2'] !== ''"
                                        class="tw-text-gray-500"
                                        >({{
                                            line.item['Description 2']
                                        }})</span
                                    >
                                </div>
                                <div
                                    v-if="line.batch !== ''"
                                    class="tw-text-gray-500"
                                >
                                    Charge: {{ line.batch }}
                                </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div class="tw-text-2xl">
                                <span class="tw-font-extrabold">{{
                                    line.quantity
                                }}</span>
                                <span class="tw-ml-2 tw-text-2xl">{{
                                    line.unit
                                }}</span>
                            </div>
                        </div>
                        <v-divider class="tw-mt-1"></v-divider>
                    </v-list-item>
                </template>
            </v-list>
        </template>
    </DialogModal>
</template>

<style scoped></style>
