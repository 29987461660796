<script setup>
import { Head, usePage } from '@inertiajs/vue3';
import { computed, inject, ref, watch } from 'vue';
import BinCard from '@/Pages/Pick/Partials/BinCard.vue';
import { mdiArrowLeft, mdiArrowRight, mdiInformation, mdiPlus } from '@mdi/js';
import PickListModal from '@/Pages/Pick/Partials/PickListModal.vue';
import usePickScans from '@/lib/composables/pick-scans.js';
import { collect } from 'collect.js';
import AppBarActionsTeleport from '@/Components/AppBarActionsTeleport.vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import PackageExpansionPanel from '@/Pages/Pick/Partials/PackageExpansionPanel.vue';
import useBarcodeScanner from '@/lib/composables/barcode-scanner.js';
import QuantityModal from '@/Pages/Pick/Partials/QuantityModal.vue';
import { trans as $t } from 'laravel-vue-i18n';

const page = usePage();
const currentPackageId = ref(page.props.pick_list.current_pick_package_id);
const currentPackageIdentifier = ref(
    page.props.pick_list.current_package.identifier,
);

const errorMessage = ref(null);

const { scan, scans, loading, transactionsPending } = usePickScans(
    page.props.pick_list.id,
    currentPackageId,
    page.props.pick_list.scans,
);

const pauseKeydownEventListener = inject('pauseCaptureKeydown');
const { onBarcodeScanned } = useBarcodeScanner({
    pauseEventListener: pauseKeydownEventListener,
});
const scanned = ref(false);
onBarcodeScanned((barcode, e) => {
    e.preventDefault();
    e.stopPropagation();
    scanned.value = barcode === currentBin.value.ean_code;

    if (scanned.value === false) {
        errorMessage.value = 'Falscher Lagerplatz gescannt.';
    }
});
const binsById = computed(() => {
    return page.props.pick_list.lines.reduce((acc, bin) => {
        acc[bin.id] = bin;
        return acc;
    }, {});
});
const scansById = computed(() => {
    return collect(scans.value).groupBy('pick_list_line_id');
});
const totalQuantity = page.props.pick_list.lines.reduce(
    (acc, line) => acc + line.quantity,
    0,
);
const scannedQuantity = computed(() =>
    scans.value.reduce((acc, scan) => acc + scan.quantity, 0),
);
const rest = computed(() => totalQuantity - scannedQuantity.value);

const currentBinIndex = ref(null);
for (let i = 0; i < page.props.pick_list.lines.length; i++) {
    const line = page.props.pick_list.lines[i];
    if (line.quantity !== scansById.value.get(line.id)?.sum('quantity') ?? 0) {
        currentBinIndex.value = i;
        break;
    }

    if (i === page.props.pick_list.lines.length - 1) {
        currentBinIndex.value = i;
    }
}

const currentBin = computed(() => {
    return page.props.pick_list.lines[currentBinIndex.value];
});

const previousBin = computed(() => {
    if (currentBinIndex.value === 0) {
        return null;
    }
    return page.props.pick_list.lines[currentBinIndex.value - 1];
});
const nextBin = computed(() => {
    if (currentBinIndex.value === page.props.pick_list.lines.length - 1) {
        return null;
    }
    return page.props.pick_list.lines[currentBinIndex.value + 1];
});

watch(currentBinIndex, () => {
    scanned.value = false;
    errorMessage.value = null;
});
const next = () => {
    if (currentBinIndex.value < page.props.pick_list.lines.length - 1) {
        currentBinIndex.value++;
    }
};
const previous = () => {
    if (currentBinIndex.value > 0) {
        currentBinIndex.value--;
    }
};
const showPickListModal = ref(false);

const confirm = (bin) => {
    scan(bin.id, bin.quantity);
    if (currentBinIndex.value < page.props.pick_list.lines.length - 1) {
        currentBinIndex.value++;
    }
};

const partialConfirm = (bin) => {
    scan(bin.id, Number(quantity.value));
    if (bin.quantity === scansById.value.get(bin.id)?.sum('quantity')) {
        if (currentBinIndex.value < page.props.pick_list.lines.length - 1) {
            currentBinIndex.value++;
        }
    }
    showQuantityModal.value = false;
};

const quantity = ref('0');
const remainingQuantity = computed(() => {
    return (
        currentBin.value.quantity -
        (scansById.value.get(currentBin.value.id)?.sum('quantity') ?? 0)
    );
});
const showQuantityModal = ref(false);
const promptAmount = (bin) => {
    showQuantityModal.value = true;
    quantity.value = (
        bin.quantity - (scansById.value.get(bin.id)?.sum('quantity') ?? 0)
    ).toString();
};

const scansByPackageId = computed(() => {
    return collect(scans.value).groupBy('pick_package_id');
});

const creatingPackage = ref(false);
const savedPackages = ref([]);
const nextPackage = async () => {
    creatingPackage.value = true;
    try {
        const response = await axios.post(
            route('api.pick.packages.create', {
                pick_list: page.props.pick_list.id,
            }),
        );

        currentPackageId.value = response.data.data.id;
        currentPackageIdentifier.value = response.data.data.identifier;
        savedPackages.value.push(response.data.data);
    } catch (e) {
        console.error(e);
    }
    creatingPackage.value = false;
};

const packages = computed(() => [
    ...page.props.pick_list.packages,
    ...savedPackages.value,
]);

const packagesById = computed(() => {
    return collect(packages.value).keyBy('id');
});
</script>

<template>
    <Head title="Pick"></Head>
    <v-container>
        <AppBarActionsTeleport>
            <v-btn
                :loading="creatingPackage"
                :disabled="
                    (scansByPackageId.get(currentPackageId)?.sum('quantity') ??
                        0) === 0 ||
                    transactionsPending ||
                    rest === 0
                "
                class="tw-mr-4"
                color="secondary"
                :prepend-icon="mdiPlus"
                @click="nextPackage"
                >PAL</v-btn
            >
            <LinkVuetify
                method="delete"
                :href="
                    route('pick.reset', { pick_list: page.props.pick_list.id })
                "
                color="danger"
                >Reset</LinkVuetify
            >
        </AppBarActionsTeleport>
        <QuantityModal
            v-model="quantity"
            :show="showQuantityModal"
            @close="showQuantityModal = false"
        >
            <v-btn color="danger" @click="showQuantityModal = false">{{
                $t('Cancel')
            }}</v-btn>
            <v-btn
                :disabled="Number(quantity) > remainingQuantity"
                color="primary"
                @click="partialConfirm(currentBin)"
                >Fertig</v-btn
            >
        </QuantityModal>
        <PickListModal
            :show="showPickListModal"
            :current-bin-index
            :lines="page.props.pick_list.lines"
            :scans-by-id
            @close="showPickListModal = false"
            @select="currentBinIndex = $event"
        ></PickListModal>
        <v-row>
            <PackageExpansionPanel
                :current-package-identifier
                :packages
                :bins-by-id
                :scans-by-package-id
            ></PackageExpansionPanel>
        </v-row>
        <v-row>
            <v-col>
                <BinCard
                    :packages-by-id
                    :rest
                    :loading="loading"
                    :bin="currentBin"
                    :previous-bin
                    :next-bin
                    :barcode-scanned="scanned"
                    :current-package-identifier
                    :scans="scansById.get(currentBin.id)"
                    :error-message
                    @confirm="confirm"
                    @partial-confirm="promptAmount"
                >
                    <v-btn
                        :disabled="previousBin === null"
                        :prepend-icon="mdiArrowLeft"
                        color="secondary"
                        variant="elevated"
                        @click="previous"
                        >Zurück</v-btn
                    >
                    <v-spacer></v-spacer>
                    <span
                        v-if="rest === 0"
                        class="tw-mr-4 tw-rounded-xl tw-border-4 tw-border-solid tw-border-white tw-bg-red-600 tw-p-4 tw-text-lg tw-font-bold tw-uppercase tw-text-white"
                        >{{ $t('Completed') }}</span
                    >
                    <v-btn color="info" @click="showPickListModal = true">
                        <v-icon :icon="mdiInformation" size="32"></v-icon>
                        <span class="tw-text-lg">Liste</span>
                    </v-btn>
                    <v-btn
                        :disabled="nextBin === null"
                        :append-icon="mdiArrowRight"
                        color="secondary"
                        variant="elevated"
                        @click="next"
                        >Nächstes</v-btn
                    >
                </BinCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped></style>
