<script setup>
import { mdiArrowRightBottom } from '@mdi/js';
import LinkVuetify from '@/Components/LinkVuetify.vue';

defineProps({
    pickList: {
        type: Object,
        required: true,
    },
});

const formatDate = (date) => {
    return moment(date).format('DD.MM.YY HH:mm');
};
</script>

<template>
    <tr class="tw-border tw-border-y-2 tw-border-solid tw-py-1">
        <td class="tw-border tw-border-y-2 tw-border-solid tw-py-1">
            <div class="tw-flex tw-w-full tw-justify-between tw-text-xl">
                <div class="tw-font-bold">
                    {{ pickList.customer_name }}
                    <span class="tw-font-normal"
                        >({{ pickList.customer_number }})</span
                    >
                </div>
                <div class="tw-accent-gray-600">
                    {{ formatDate(pickList.created_at) }}
                </div>
            </div>
            <div class="tw-flex tw-w-full tw-justify-between">
                <div>{{ pickList.ean }}</div>

                <!--                <div class="tw-accent-gray-600">{{ pickList.created_by }}</div>-->
            </div>
            <div
                class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-1 tw-text-3xl"
            >
                <div class="tw-flex">
                    <div
                        class="tw-mr-2 tw-rounded tw-border tw-border-solid tw-p-1 tw-text-center"
                    >
                        A:
                        <span class="tw-font-bold">{{
                            pickList.delivery_note_count
                        }}</span>
                    </div>
                    <div
                        class="tw-mr-2 tw-rounded tw-border tw-border-solid tw-p-1 tw-text-center"
                    >
                        L:
                        <span class="tw-font-bold">{{
                            pickList.bin_count
                        }}</span>
                    </div>
                    <div
                        class="tw-rounded tw-border tw-border-solid tw-p-1 tw-text-center"
                    >
                        P:
                        <span class="tw-font-bold">{{
                            pickList.pick_count
                        }}</span>
                    </div>
                </div>
                <div class="tw-flex tw-flex-col">
                    <LinkVuetify
                        class="tw-w-auto"
                        :href="route('pick.show', pickList.ean)"
                        as="v-btn"
                        :color="
                            pickList.started_at === null ? 'success' : 'info'
                        "
                        :append-icon="mdiArrowRightBottom"
                        >{{
                            pickList.started_at === null
                                ? 'Start'
                                : 'Fortsetzen'
                        }}
                    </LinkVuetify>
                    <small
                        v-if="pickList.started_at !== null"
                        class="tw-mt-1 tw-text-xs"
                        >gestarted {{ formatDate(pickList.started_at) }}</small
                    >
                </div>
            </div>
        </td>
    </tr>
</template>

<style scoped></style>
