<script setup>
import { computed } from 'vue';
import { mdiAlert, mdiNumeric } from '@mdi/js';
import { collect } from 'collect.js';

const props = defineProps({
    bin: {
        type: Object,
        required: true,
    },
    previousBin: {
        type: [Object, null],
        required: true,
    },
    nextBin: {
        type: [Object, null],
        required: true,
    },
    currentPackageIdentifier: {
        type: String,
        required: true,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    //Collection
    scans: {
        type: [Object, null],
        required: true,
    },
    rest: {
        type: Number,
        required: true,
    },
    barcodeScanned: {
        type: Boolean,
        required: true,
    },
    errorMessage: {
        type: [String, null],
        default: null,
        required: false,
    },
    packagesById: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['confirm', 'partialConfirm']);
const confirm = () => {
    emit('confirm', props.bin);
};

const partialConfirm = () => {
    emit('partialConfirm', props.bin);
};

const scanQuantity = computed(() => {
    return props.scans?.sum('quantity') ?? 0;
});

const scanned = computed(() => {
    return scanQuantity.value === props.bin.quantity;
});

const scansByPackage = computed(() => {
    return Object.entries(
        collect(props.scans)
            .groupBy('pick_package_id')
            .map((scans) => scans.sum('quantity'))
            .mapWithKeys((quantity, packageId) => [
                props.packagesById.get(packageId).identifier,
                quantity,
            ])
            .all(),
    );
});
</script>

<template>
    <div>
        <v-alert
            v-if="errorMessage"
            class="tw-mb-2"
            :icon="mdiAlert"
            color="danger"
            variant="outlined"
        >
            {{ errorMessage }}
        </v-alert>
        <v-card
            :class="{
                'tw-border-2 tw-border-solid tw-border-red-500':
                    errorMessage !== null,
            }"
        >
            <v-toolbar>
                <v-toolbar-items class="tw-w-full">
                    <slot></slot>
                </v-toolbar-items>
            </v-toolbar>
            <div
                class="tw-m-1 tw-p-4"
                :class="{
                    'tw-rounded-lg tw-border tw-border-solid  tw-border-success':
                        scanned,
                }"
            >
                <div class="tw-flex">
                    <div v-if="previousBin !== null">
                        <div>
                            <span>vorheriger: </span
                            ><span class="tw-font-bold">
                                {{ previousBin.bin_code }}</span
                            ><span class="tw-ml-4"
                                >{{ previousBin.quantity }}
                                {{ previousBin.unit }}</span
                            >
                        </div>
                        <div>
                            <span>{{ previousBin.item.Description }}</span>
                            <span
                                v-if="previousBin.item['Description 2'] !== ''"
                            >
                                ({{ previousBin.item['Description 2'] }})
                            </span>
                        </div>
                    </div>
                </div>
                <v-divider class="tw-mt-2"></v-divider>
                <div>
                    <div>
                        <span class="tw-text-2xl tw-font-bold">{{
                            bin.bin_code
                        }}</span>
                    </div>
                    <div>
                        <span class="tw-text-gray-500">{{
                            bin.item.Description
                        }}</span>
                        <span
                            v-if="bin.item['Description 2'] !== ''"
                            class="tw-text-gray-500"
                            >({{ bin.item['Description 2'] }})</span
                        >
                    </div>
                    <div
                        v-if="bin.batch !== '' && bin.batch !== null"
                        class="tw-text-gray-500"
                    >
                        Charge: {{ bin.batch }}
                    </div>
                    <div class="tw-text-4xl">
                        <span class="tw-font-extrabold tw-text-red-500">{{
                            bin.quantity
                        }}</span>
                        <span class="tw-ml-2 tw-text-2xl">{{ bin.unit }}</span>
                    </div>
                </div>
                <div class="tw-text-lg tw-font-bold tw-text-cyan-500">
                    <span v-if="!scanned"
                        >Ziel: {{ currentPackageIdentifier }}</span
                    >
                </div>
                <div
                    v-if="scans?.count() ?? 0 > 0"
                    class="tw-text-lg tw-font-bold tw-text-blue-500"
                >
                    <div><span> Gepickt auf: </span></div>
                    <div>
                        <ul>
                            <li
                                v-for="(scan, index) in scansByPackage"
                                :key="index"
                                class="tw-list-none"
                            >
                                <span
                                    >{{ scan[0] }} - {{ scan[1] }}
                                    {{ bin.unit }}</span
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <span>Rest: {{ rest }}</span>
                </div>
                <div v-if="!scanned" class="tw-mt-4 tw-flex tw-items-center">
                    <v-btn
                        class="tw-mr-2"
                        :disabled="barcodeScanned"
                        size="large"
                        color="primary"
                        @click="confirm"
                        >Fertig
                    </v-btn>
                    <v-btn
                        :disabled="!barcodeScanned"
                        color="info"
                        :icon="mdiNumeric"
                        @click="partialConfirm"
                    ></v-btn>
                </div>

                <v-btn
                    v-else
                    size="large"
                    class="tw-mt-4"
                    variant="outlined"
                    color="primary"
                    >Gepickt
                </v-btn>
                <v-divider class="tw-mb-4 tw-mt-2"></v-divider>
                <div>
                    <div v-if="nextBin !== null">
                        <div>
                            <span>vorheriger: </span
                            ><span class="tw-font-bold">
                                {{ nextBin.bin_code }}</span
                            ><span class="tw-ml-4"
                                >{{ nextBin.quantity }} {{ nextBin.unit }}</span
                            >
                        </div>
                        <div>
                            <span>{{ nextBin.item.Description }}</span>
                            <span v-if="nextBin.item['Description 2'] !== ''">
                                ({{ nextBin.item['Description 2'] }})
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>

<style scoped></style>
