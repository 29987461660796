import { useLocalStorage, watchDebounced } from '@vueuse/core';
import { computed, reactive, ref, watch } from 'vue';

export default function usePickScans(pickListId, packageId, initialScans) {
    const errorMessage = ref(null);
    const loading = ref(false);
    const transactionsPending = ref(false);

    const scanTransactions = useLocalStorage(
        'pick_list_scans_' + pickListId,
        {
            timestamp: null,
            newScans: [],
            deletedScans: [],
        },
        {
            mergeDefaults: true,
        },
    );

    const savingScans = reactive({
        newScans: [],
        deletedScans: [],
    });
    const savedScans = reactive({
        newScans: [],
        deletedScans: [],
    });

    const scanTemplate = {
        pick_package_id: null,
        pick_list_line_id: null,
        quantity: null,
    };
    const scan = (lineId, quantity) => {
        scanTransactions.value.newScans.push({
            id: 'new-' + Date.now(),
            ...scanTemplate,
            pick_package_id: packageId.value,
            pick_list_line_id: lineId,
            quantity,
        });
        scanTransactions.value.timestamp = Date.now();
    };

    const deleteScan = (scanId) => {
        const transaction = scanTransactions.value.newScans.find(
            (scan) => scan.id === scanId,
        );
        if (transaction) {
            const index = scanTransactions.value.newScans.indexOf(transaction);
            scanTransactions.value.newScans.splice(index, 1);
        } else {
            // TODO: fix

            scanTransactions.value.deletedScans.push(scanId);
            scanTransactions.value.timestamp = Date.now();
        }
    };

    const scans = computed(() => {
        return [
            ...initialScans,
            ...scanTransactions.value.newScans,
            ...savingScans.newScans,
            ...savedScans.newScans,
        ].filter(
            (scan) =>
                !scanTransactions.value.deletedScans.includes(scan.id) &&
                !savingScans.deletedScans.includes(scan.id) &&
                !savedScans.deletedScans.includes(scan.id),
        );
    });

    const submitTransactions = async () => {
        console.log('savingScans', savingScans);
        loading.value = true;
        try {
            savingScans.newScans = scanTransactions.value.newScans;
            savingScans.deletedScans = scanTransactions.value.deletedScans;
            scanTransactions.value.newScans = [];
            scanTransactions.value.deletedScans = [];

            const response = await axios.post(
                route('api.pick.scans', { pick_list: pickListId }),
                {
                    insert: savingScans.newScans,
                    delete: savingScans.deletedScans,
                },
            );

            savedScans.newScans.push(...response.data.data.inserted);
            savedScans.deletedScans.push(...response.data.data.deleted);
        } catch (error) {
            console.error(error);
        } finally {
            savingScans.newScans = [];
            savingScans.deletedScans = [];
            loading.value = false;
        }
    };

    watch(
        scanTransactions,
        () => {
            transactionsPending.value =
                scanTransactions.value.newScans.length > 0 ||
                scanTransactions.value.deletedScans.length > 0 ||
                savingScans.newScans.length > 0 ||
                savingScans.deletedScans.length > 0;
        },
        {
            deep: true,
        },
    );

    watchDebounced(() => scanTransactions.value.timestamp, submitTransactions, {
        debounce: 1000,
    });

    if (
        scanTransactions.value.newScans.length > 0 ||
        scanTransactions.value.deletedScans.length > 0
    ) {
        submitTransactions();
    }

    return {
        scan,
        scans,
        loading,
        errorMessage,
        deleteScan,
        transactionsPending,
    };
}
