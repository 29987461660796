<script setup>
import { usePage } from '@inertiajs/vue3';
import PickIndexTableRow from '@/Pages/Pick/Partials/PickIndexTableRow.vue';

const page = usePage();
</script>

<template>
    <v-container fluid>
        <v-card>
            <v-table>
                <tbody>
                    <PickIndexTableRow
                        v-for="pickList in page.props.pick_lists"
                        :key="pickList.ean"
                        :pick-list="pickList"
                    ></PickIndexTableRow>
                </tbody>
            </v-table>
        </v-card>
    </v-container>
</template>

<style scoped></style>
